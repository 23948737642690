<script setup>
import Doughnut from '@common/Doughnut'
import { computed, ref, watchEffect, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const props = defineProps({
  info: Object
})
const resolved = ref(false)
const count = ref(0)

const male = computed(() => {
  return props.info.gender.male
})
const female = computed(() => {
  return props.info.gender.female
})
const sum = computed(() => {
  return male.value + female.value
})
const malePercent = computed(() => {
  if (sum.value === 0) return 0
  else {
    const n = Math.round(
      (100 * (male.value === 0 ? 0 : male.value)) / sum.value
    )
    return n
  }
})
const femalePercent = computed(() => {
  if (sum.value === 0) return 0
  else {
    const n = Math.round(
      (100 * (female.value === 0 ? 0 : female.value)) / sum.value
    )
    return n
  }
})

watchEffect(() => {
  if (props.info) {
    resolved.value = true
  }
})

// enter customers
const store = useStore()
const router = useRouter()
const enterCustomers = (gender) => {
  if ((gender == 1 && male.value === 0) || (gender == 2 && female.value === 0))
    return store.dispatch('addToast', {
      type: 'error',
      message: 'در این گروه مشتری وجود ندارد.'
    })
  router.push({
    name: 'Customer',
    query: { gender: gender, type: 7 }
  })
}
</script>

<template>
  <div class="home-chart-wrapper">
    <loader v-if="!resolved" />
    <div class="hist-body" v-else>
      <div class="gender-parts-wrapper">
        <div class="gender">
          <a @click="enterCustomers(1)" class="gender__part gender--m">
            <svg class="gender__arrow" viewBox="0 0 512 512">
              <path
                d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
              />
            </svg>
            <div class="gender__stat">
              <h2 class="gender__percent">{{ malePercent }}%</h2>
              <h6 class="gender__count">
                مرد <span style="font-weight: bold">{{ male }}</span> نفر
              </h6>
            </div>
          </a>
          <i class="gender__seperator" />
          <a @click="enterCustomers(2)" class="gender__part gender--f">
            <svg class="gender__arrow" viewBox="0 0 512 512">
              <path
                d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
              />
            </svg>
            <div class="gender__stat">
              <h2 class="gender__percent">{{ femalePercent }}%</h2>
              <h6 class="gender__count">
                زن <span style="font-weight: bold">{{ female }}</span> نفر
              </h6>
            </div>
          </a>
        </div>
        <div class="doughnut-wrapper">
          <doughnut
            :size="150"
            :dataset="[
              male !== 0 ? male : 0.001,
              female !== 0 ? female : 0.001
            ]"
            :colors="['#c69d4e', '#fff']"
            :thickness="15"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.first-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.hist-body {
  width: 96%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
}
</style>
