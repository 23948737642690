<template>
  <div class="row">
    <h4 class="row__title">{{ title }}</h4>
    <h4 class="row__sum">{{ counter?.toLocaleString() }}</h4>
    <span class="row__badge">{{ customers?.toLocaleString() }}</span>
  </div>
</template>

<script>
export default {
  name: 'TableRow',
  props: {
    title: String,
    counter: {
      type: Number,
      default: 0
    },
    customers: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 5fr 6fr 25%;
  column-gap: 5px;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}
.row__sum {
  color: #333;
  position: relative;
  justify-self: start;
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  left: 8px;
  &::after {
    content: 'R';
    position: relative;
    font-size: 9px;
    top: -4px;
    right: -1px;
    font-weight: normal;
  }
}
.row__badge {
  text-align: center;
  height: 35px;
  line-height: 35px;
  padding: 1px 0 0;
  border-radius: 0 15px 15px 0;
  font-size: 14px;
  font-weight: bold;
  background-color: #a48f6c;
  color: #fff;
}

.row__title {
  width: 100%;
  font-size: 14px;
  text-align: right;
  color: #111;
  margin-right: 10px;
}
.row__arrow {
  fill: rgba(255, 255, 255, 0.5);
  width: 17px;
  height: 17px;
}
</style>
