<template>
  <div class="links">
    <div class="links__dark">
      <a class="link" @click="$store.dispatch('handleModalFooter', true)">
        <svg viewBox="0 0 700 512" class="link__icon">
          <path
            d="M191.19 113.94l117.76 0c7.16,-0.65 12.57,-6.7 12.57,-13.77 0,-7.17 -5.41,-13.13 -12.57,-13.78l-0.56 -0.09 -69.08 0 0 -73.08 0 -0.65c-0.65,-7.08 -6.61,-12.57 -13.77,-12.57 -7.17,0 -13.13,5.49 -13.78,12.57l0 0.56 0 73.17 -20.57 0c-50.46,0 -91.79,41.33 -91.79,91.78 0,50.46 41.33,91.79 91.79,91.79l68.7 0c35.28,0 64.14,28.77 64.14,64.05 0,35.28 -28.86,64.14 -64.14,64.14l-117.21 0 -0.55 0.09c-7.17,0.65 -12.57,6.61 -12.57,13.68 0,7.17 5.4,13.13 12.57,13.78l0.55 0.09 69.08 0 0 73.08 0 0.65c0.65,7.08 6.61,12.57 13.78,12.57 7.16,0 13.12,-5.49 13.77,-12.57l0 -0.56 0 -73.17 20.58 0c50.45,0 91.78,-41.33 91.78,-91.78 0,-50.46 -41.33,-91.7 -91.78,-91.7l-68.7 0c-35.38,0 -64.14,-28.86 -64.14,-64.14 0,-35.28 28.85,-64.14 64.14,-64.14l0 0 0 0zm323.12 42.17l-27.19 0 0 86.3 -86.29 0 0 27.18 86.29 0 0 86.3 27.19 0 0 -86.3 86.29 0 0 -27.18 -86.29 0 0 -86.3z"
          />
        </svg>

        <span class="link__title">ثبت وفاداری</span>
      </a>
      <i class="link__hr"></i>
      <router-link class="link" :to="{ name: 'PlanHome' }">
        <svg viewBox="0 0 700 512" class="link__icon">
          <path
            d="M345.08 512.9c0.01,0 0.03,0.01 0.05,0.01 0.16,0.04 0.3,0.06 0.43,0.1 0.54,0.14 1.07,0.25 1.61,0.33 0.24,0.04 0.47,0.08 0.69,0.11 0.66,0.07 1.3,0.13 1.96,0.13 0.07,0 0.14,0 0.21,0.01 0.06,0 0.13,-0.01 0.2,-0.01 0.66,0 1.3,-0.06 1.93,-0.13 0.24,-0.03 0.47,-0.07 0.71,-0.11 0.54,-0.08 1.08,-0.19 1.64,-0.34 0.12,-0.03 0.23,-0.05 0.36,-0.08 0.04,-0.01 0.08,-0.02 0.12,-0.03 0.04,-0.01 0.07,-0.03 0.09,-0.03 0.23,-0.06 0.43,-0.15 0.63,-0.2 0.33,-0.11 0.62,-0.22 0.9,-0.32 0.12,-0.06 0.23,-0.11 0.36,-0.15 0.11,-0.05 0.21,-0.1 0.32,-0.15 0.28,-0.13 0.55,-0.25 0.81,-0.38 0.15,-0.09 0.3,-0.17 0.5,-0.3 0.31,-0.17 0.61,-0.34 0.94,-0.56 0.16,-0.1 0.29,-0.21 0.41,-0.28 0.27,-0.19 0.52,-0.38 0.77,-0.59 0.07,-0.06 0.14,-0.12 0.22,-0.17 0.03,-0.02 0.05,-0.04 0.07,-0.05 0.06,-0.06 0.11,-0.12 0.17,-0.16 0.27,-0.24 0.52,-0.47 0.79,-0.75 0.09,-0.09 0.19,-0.18 0.29,-0.26l304.1 -324.21c3.91,-4.17 4.64,-10.08 1.52,-14.92l-103.39 -160.87c-2.93,-4.56 -8.38,-6.95 -13.7,-6.95l-401.57 0c-5.32,0 -10.77,2.39 -13.7,6.95l-103.4 160.87c-3.11,4.84 -2.39,10.75 1.52,14.92l304.15 324.2c0.11,0.12 0.21,0.19 0.29,0.29 0.25,0.24 0.49,0.47 0.74,0.7 0.07,0.05 0.13,0.1 0.18,0.16 0.01,0.01 0.02,0.02 0.03,0.02 0.09,0.06 0.16,0.12 0.21,0.17 0.29,0.23 0.56,0.44 0.88,0.67 0.13,0.09 0.26,0.18 0.4,0.26 0.31,0.21 0.62,0.39 0.92,0.57 0.16,0.09 0.31,0.17 0.46,0.26 0.28,0.14 0.54,0.25 0.77,0.37 0.16,0.06 0.29,0.12 0.42,0.19 0.12,0.05 0.21,0.09 0.29,0.13 0.34,0.12 0.65,0.22 0.96,0.33 0.22,0.07 0.41,0.15 0.63,0.21l0.09 0.03 0.02 0.01zm46.47 -484.42l58.89 133.98 -200.86 0 58.92 -133.98 83.05 0zm59.98 160.86l-101.51 267.43 -101.51 -267.43 203.02 0zm-235.95 0l93.13 245.35 -230.18 -245.35 137.05 0zm268.87 0l137.01 0 -230.13 245.34 93.12 -245.34zm143.21 -26.88l-143.75 0 -58.91 -133.98 116.55 0 86.11 133.98zm-469.2 -133.98l116.57 0 -58.91 133.98 -143.78 0 86.12 -133.98z"
          />
        </svg>
        <span class="link__title">طرح وفاداری</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  setup() {}
}
</script>

<style scoped lang="scss">
.links {
  width: calc(94% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 30px;
}
.links__dark {
  width: 100%;
  height: 110px;
  background-color: #000;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  cursor: pointer;
  width: calc(50% - 1px);
  position: relative;
  z-index: auto;
  border-radius: 15px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.link__icon {
  fill: #c69d4e;
  height: 40px;
  width: auto;
  margin-bottom: 10px;
}
.link__title {
  font-size: 13px;
  text-align: center;
  color: white;
}
.link__hr {
  height: 70%;
  width: 2px;
  background-color: rgba(#fff, 0.2);
}
</style>
