<script setup>
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import HomeCustomerAge from './subComponents/HomeCustomerAge'
// import HomeCustomerLocation from './subComponents/HomeCustomerLocation'
import HomeCustomerGender from './subComponents/HomeCustomerGender'
import HomeCustomerSort from './subComponents/HomeCustomerSort'
import HomeCustomerLabel from './subComponents/HomeCustomerLabel'

const store = useStore()
const router = useRouter()

// Initial api call
store.dispatch('customer/fetchCustomersInfo')

const groups = computed(() => store.state.customer.groups)
const info = computed(() => store.state.customer.customersInfo)
// const cities = computed(() => store.state.customer.cities)

// Handle page status
const pageStatus = computed(() => store.state.customer.pageStatus)

const enterUncompleted = () => {
  if (info.value.uncompleted === 0)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'مشتری تکمیل نشده وجود ندارد.'
    })
  router.push({ name: 'Customer', query: { type: 8 } })
}

// Handle intersections
// const cityStatus = ref('')
// const handleCityIntersect = () => {
//   if (cities.value['cities'] || cityStatus.value == 'loading') return
//   cityStatus.value = 'loading'
//   store.dispatch('customer/fetchCustomerCities')
// }
// watchEffect(() => {
//   if (cities.value['cities']) cityStatus.value = 'resolved'
// })

const groupStatus = ref('')
const handleGroupIntersect = () => {
  if (groups.value || groupStatus.value == 'loading') return
  groupStatus.value = 'loading'
  store.dispatch('customer/fetchCustomerGroups')
}
watchEffect(() => {
  if (groups.value) groupStatus.value = 'resolved'
})

const labelStatus = ref('')
const handleLabelIntersect = () => {
  if (labelStatus.value == 'loading' || labelStatus.value == 'resolved') return
  labelStatus.value = 'loading'
}

onBeforeUnmount(() => store.dispatch('customer/clearCustomersInfo'))
onBeforeRouteLeave((to) => {
  if (!to.name == 'CustomerLocation')
    store.dispatch('customer/clearCustomerCities')
})
</script>

<template>
  <div class="cmp-wrapper">
    <navbar msg="مشتریان" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode
      v-else-if="pageStatus == 'failed'"
      @refresh="store.dispatch('customer/fetchCustomersInfo')"
    />
    <section class="load-resolved" v-else>
      <div class="customers">
        <span>{{ info?.count }}</span>
      </div>
      <div class="cmp-items-wrapper">
        <div class="stat-title">
          <svg class="stat-title__icon" viewBox="0 0 700 512">
            <path
              d="M349.52 43.7c28.36,-27.94 66.28,-43.7 106.27,-43.7 84.26,0 152.02,69.05 152.02,153.08 0,78.6 -59.55,144.94 -137.52,152.39l0 150.21 48.97 -48.97 20.34 20.34 -83.81 84.95 -83.82 -84.95 20.35 -20.34 48.96 48.97 0 -150.21c-34.69,-3.32 -66.96,-18.58 -91.76,-43 -24.71,23.81 -56.61,38.66 -90.8,41.94l0 109.22 63.47 0 0 29.01 -63.47 0 0 63.46 -29.01 0 0 -63.46 -63.47 0 0 -29.01 63.47 0 0 -109.22c-77.41,-7.4 -137.52,-73.13 -137.52,-151.33 0,-83.62 68.4,-152.02 152.02,-152.02 39.48,0 77,15.35 105.31,42.64zm19.37 22.66c17.8,25.47 27.35,55.57 27.35,86.72 0,31.67 -9.86,62.25 -28.22,87.95 23.09,23.71 54.51,37.19 87.77,37.19 68.29,0 123.02,-56.01 123.02,-124.08 0,-68.06 -54.73,-124.08 -123.02,-124.08 -32.82,0 -63.88,13.12 -86.9,36.3zm-38.05 -0.41c-23.04,-22.92 -54,-35.89 -86.63,-35.89 -67.65,0 -123.02,55.37 -123.02,123.02 0,67.66 55.37,123.02 123.02,123.02 32.63,0 63.59,-12.97 86.63,-35.88 -17.71,-25.66 -27.08,-55.9 -27.08,-87.14 0,-31.24 9.37,-61.47 27.08,-87.13zm18.63 150.61c11.61,-19.14 17.76,-40.98 17.76,-63.48 0,-22.08 -5.92,-43.53 -17.14,-62.42 -11.38,19.21 -17.32,41.04 -17.32,63.48 0,22.02 5.71,43.45 16.7,62.42z"
            />
          </svg>
          <h5 class="stat-title__text">جنسیت</h5>
        </div>
        <home-customer-gender :info="info" />
        <!-- CITIES -->
        <!-- <observer @intersect="handleCityIntersect" :trigger-margin="30" />
        <loader v-if="cityStatus == 'loading'" />
        <template v-if="cityStatus == 'resolved'">
          <div class="stat-title">
            <svg class="stat-title__icon" viewBox="0 0 700 512">
              <path
                d="M350 0c-99.25,0 -180,80.75 -180,180 0,33.53 9.29,66.26 26.87,94.65l142.88 230.26c2.74,4.41 7.56,7.09 12.75,7.09 0.04,0 0.08,0 0.12,0 5.23,-0.04 10.06,-2.8 12.75,-7.29l139.24 -232.49c16.61,-27.79 25.39,-59.68 25.39,-92.22 0,-99.25 -80.75,-180 -180,-180l0 0zm128.87 256.82l-126.6 211.37 -129.9 -209.34c-14.64,-23.64 -22.57,-50.9 -22.57,-78.85 0,-82.71 67.49,-150.2 150.2,-150.2 82.71,0 150.1,67.49 150.1,150.2 0,27.12 -7.41,53.69 -21.23,76.82z"
              />
              <path
                d="M350 90c-49.63,0 -90,40.37 -90,90 0,49.31 39.72,90 90,90 50.9,0 90,-41.23 90,-90 0,-49.63 -40.37,-90 -90,-90zm0 150.2c-33.26,0 -60.2,-27.03 -60.2,-60.2 0,-33.08 27.11,-60.2 60.2,-60.2 33.08,0 60.1,27.12 60.1,60.2 0,32.68 -26.32,60.2 -60.1,60.2z"
              />
            </svg>
            <h5 class="stat-title__text">شهر ها</h5>
          </div>
          <home-customer-location
            :cities="cities['cities']"
            :count="cities['count']"
          /> -->
        <!-- GROUPS -->
        <observer @intersect="handleGroupIntersect" :trigger-margin="30" />
        <loader v-if="groupStatus == 'loading'" />
        <template v-if="groupStatus == 'resolved'">
          <div class="stat-title">
            <svg class="stat-title__icon" viewBox="0 0 700 512">
              <path
                d="M212.46 353.38l-85.71 0c-3.52,0 -6.9,1.37 -9.39,3.86 -2.49,2.49 -3.9,5.87 -3.91,9.39l-0.03 132.08c0,3.52 1.42,6.9 3.91,9.39 2.49,2.5 5.86,3.9 9.39,3.9l462.13 -0.03c7.35,0 13.27,-5.95 13.27,-13.29l0.03 -429.12c0,-3.52 -1.39,-6.89 -3.88,-9.39 -2.49,-2.49 -5.87,-3.89 -9.39,-3.89l-132.05 0c-7.35,0 -13.29,5.94 -13.29,13.29l0 85.74 -118.76 0c-7.33,0 -13.28,5.95 -13.28,13.29l0 85.78 -85.76 -0.03c-3.51,0 -6.9,1.4 -9.39,3.89 -2.49,2.49 -3.89,5.86 -3.89,9.39l0 85.75zm363.13 132.05l-435.6 0 0 -105.52 85.76 0.04c3.51,0 6.9,-1.4 9.39,-3.89 2.48,-2.49 3.9,-5.87 3.9,-9.39l0 -85.75 85.71 0c3.52,0 6.91,-1.36 9.39,-3.85 2.49,-2.5 3.89,-5.88 3.9,-9.4l0.03 -85.79 118.76 0c7.35,0 13.28,-5.94 13.28,-13.28l0 -85.76 105.48 0 0 402.59zm-287.45 -403.06l0 -82.37 -82.37 0 31.78 31.79 -139.7 140.05 18.45 18.45 140.05 -139.72 31.79 31.8z"
              />
            </svg>
            <h5 class="stat-title__text">سطح</h5>
          </div>
          <home-customer-sort :groups="groups" />
          <!-- LABELS -->
          <observer @intersect="handleLabelIntersect" :trigger-margin="30" />
          <loader v-if="labelStatus == 'loading'" />
          <template v-if="labelStatus == 'resolved'">
            <div class="stat-title">
              <svg class="stat-title__icon" viewBox="0 0 700 512">
                <path
                  d="M525.69 322.72l0 -82.78c0,-8.33 -6.74,-15.06 -15.06,-15.06l-145.57 0 0 -35.6 0.84 -0.14c45.68,-7.74 79.47,-47.3 79.47,-93.77 0,-52.59 -42.78,-95.37 -95.37,-95.37 -52.59,0 -95.37,42.78 -95.37,95.37 0,46.47 33.79,86.03 79.48,93.77l0.83 0.14 0 35.6 -145.57 0c-8.32,0 -15.06,6.73 -15.06,15.06l0 82.78 -0.83 0.14c-45.68,7.74 -79.48,47.3 -79.48,93.77 0,52.59 42.78,95.37 95.37,95.37 52.59,0 95.38,-42.78 95.38,-95.37 0,-46.47 -33.8,-86.03 -79.48,-93.77l-0.84 -0.14 0 -67.72 291.14 0 0 67.72 -0.84 0.14c-45.68,7.74 -79.48,47.3 -79.48,93.77 0,52.59 42.78,95.37 95.37,95.37 52.6,0 95.37,-42.78 95.38,-95.37 0,-46.47 -33.79,-86.03 -79.48,-93.77l-0.83 -0.14zm-240.94 -227.35c0,-35.99 29.26,-65.25 65.25,-65.25 35.99,0 65.25,29.26 65.25,65.25 0,35.99 -29.26,65.26 -65.25,65.26 -35.99,0 -65.25,-29.27 -65.25,-65.26zm-30.12 321.26c0,35.99 -29.27,65.25 -65.26,65.25 -35.99,0 -65.25,-29.26 -65.25,-65.25 0,-35.99 29.27,-65.26 65.25,-65.26 35.99,0 65.26,29.27 65.26,65.26zm256 65.25c-35.99,0 -65.26,-29.26 -65.26,-65.25 0,-35.99 29.27,-65.26 65.26,-65.26 35.99,0 65.25,29.27 65.25,65.26 0,35.99 -29.26,65.25 -65.25,65.25z"
                />
              </svg>
              <h5 class="stat-title__text">گروه‌ بندی</h5>
            </div>
          </template>
          <home-customer-label
            @labelsResolved="labelStatus = 'resolved'"
            v-if="labelStatus == 'loading' || labelStatus == 'resolved'"
            v-show="labelStatus == 'resolved'"
          />
          <div class="stat-title">
            <svg class="stat-title__icon" viewBox="0 0 700 512">
              <path
                d="M119.08 512l461.84 0 0 -30.11 0 0 0 -35.74 0 -152.85 0 -35.06c0,-45.14 -24.76,-86.53 -64.37,-108.1l-6.09 -3.31 4.97 -4.82c16.11,-15.62 25.18,-36.88 25.18,-59.33 0,-45.59 -37.09,-82.68 -82.68,-82.68 -45.58,0 -82.68,37.09 -82.68,82.68 0,22.45 9.08,43.71 25.18,59.33l4.97 4.82 -6.08 3.31c-39.61,21.57 -64.38,62.96 -64.38,108.1l0 35.06 -8.59 -8.1c-7.72,-7.27 -16.36,-13.54 -25.67,-18.62l-6.08 -3.31 4.97 -4.82c16.11,-15.62 25.18,-36.88 25.18,-59.33 0,-45.58 -37.09,-82.68 -82.68,-82.68 -45.59,0 -82.68,37.1 -82.68,82.68 0,22.45 9.07,43.71 25.18,59.33l4.97 4.82 -6.09 3.31c-39.61,21.57 -64.37,62.96 -64.37,108.1l0 137.32zm431.73 -253.76l0 223.65 -185.75 0 0 -223.65c0,-51.22 41.66,-92.88 92.87,-92.88 51.22,0 92.88,41.66 92.88,92.88zm-92.88 -122.99c-28.99,0 -52.57,-23.58 -52.57,-52.57 0,-28.99 23.58,-52.57 52.57,-52.57 28.99,0 52.57,23.58 52.57,52.57 0,28.99 -23.57,52.57 -52.57,52.57zm-122.99 239.43l0 107.21 -185.75 0 0 -107.21c0,-51.22 41.66,-92.88 92.88,-92.88 51.21,0 92.87,41.66 92.87,92.88zm-92.87 -122.99c-28.99,0 -52.57,-23.58 -52.57,-52.57 0,-28.99 23.58,-52.57 52.57,-52.57 28.99,0 52.57,23.58 52.57,52.57 0,28.99 -23.58,52.57 -52.57,52.57z"
              />
            </svg>
            <h5 class="stat-title__text">بازه سنی</h5>
          </div>
          <home-customer-age :info="info" />
          <div class="stat-buttons">
            <!-- <div class="stat-button">
              <h5>همه مشتریان</h5>
              <h6>{{ info?.count }}</h6>
            </div> -->
            <a class="stat-button" @click="enterUncompleted">
              <h5>
                مشتریان تکمیل
                <br />
                عضویت نشده
              </h5>
              <h6>
                <span>{{ info.uncompleted ? info.uncompleted : 0 }}</span>
                <svg viewBox="0 0 512 512">
                  <path
                    d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
                  />
                </svg>
              </h6>
            </a>
          </div>
        </template>
        <!-- </template> -->
      </div>
    </section>
    <fixed-footer />
  </div>
</template>

<style lang="scss" scoped>
.stat-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  // justify-content: space-between;
}
.stat-button {
  width: 96%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  border-radius: 15px;
  color: #fff;
  padding: 0 22px 0 10px;
  cursor: pointer;
  h5 {
    font-size: 14px;
    line-height: 26px;
  }
  h6 {
    position: relative;
    font-size: 36px;
    color: #d40000;
    display: flex;
    align-items: center;
    span {
      position: relative;
      top: 5px;
      animation: blinking 1.3s infinite;
      margin-left: 5px;
    }
    svg {
      width: 20px;
      height: 20px;
      fill: #555;
    }
  }
}
.customers {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  width: 100%;
  font-size: 40px;
  background: linear-gradient(to bottom, #000 0, #c69d4e 200%);
  padding-top: 70px;
  span {
    animation: blinking 1.3s infinite;
  }
}
.stat-title {
  width: 96%;
  border-radius: 30px 30px 0 0;
  background-color: #181818;
  padding: 10px 0 50px;
  margin-bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 10px;
}
.stat-title__text {
  font-size: 16px;
  color: #fff;
}
.stat-title__icon {
  fill: #777;
  margin-left: 5px;
  width: 30px;
  height: 30px;
}

// change in common css
.cmp-wrapper {
  padding-top: 0;
  background-color: #fff;
}
.cmp-items-wrapper {
  background-color: #fff;
  margin-top: -40px;
  padding-top: 40px;
  z-index: 1;
  border-radius: 40px 40px 0 0;
}
.load-pending--show {
  padding-top: 70px;
  height: calc(100vh - 135px);
}
</style>
