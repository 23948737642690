<template>
  <div class="home-wrapper">
    <home-header />
    <section class="middle">
      <home-button-sell />
      <home-button-customer
        :customers="homeData?.customers"
        :comments="homeData?.vote_count"
      />
      <HomeButtonBonus />
      <!-- <home-button-stat /> -->
      <!-- <home-profile :logo="homeData?.image" :wallet="homeData?.wallet" /> -->
      <!-- <home-chest :price="homeData?.cashiers" /> -->
    </section>
    <section class="navs">
      <home-nav />
      <!-- <home-banner
        :customers="homeData?.customers"
        :satisfy="homeData?.rating"
      /> -->
      <!-- <home-learn /> -->
    </section>
    <section class="down">
      <!-- <home-button-group /> -->
      <!-- <i class="home-hr"></i> -->
      <!-- <home-chest :price="homeData?.cashiers" /> -->
      <home-button-lottery />
      <home-down />
    </section>
    <fixed-footer />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { isComplete } from '@service/TokenService'
import HomeButtonSell from './subComponents/HomeButtonSell'
import HomeButtonCustomer from './subComponents/HomeButtonCustomer'
import HomeButtonStat from './subComponents/HomeButtonStat'
import HomeButtonBonus from './subComponents/HomeButtonBonus'
import HomeButtonLottery from './subComponents/HomeButtonLottery'
import HomeButtonGroup from './subComponents/HomeButtonGroup'
import HomeHeader from './subComponents/HomeHeader'
import HomeProfile from './subComponents/HomeProfile'
import HomeNav from './subComponents/HomeNav'
import HomeChest from './subComponents/HomeChest'
import HomeDown from './subComponents/HomeDown'
// import HomeBanner from './subComponents/HomeBanner'
import HomeLearn from './subComponents/HomeLearn'

// Check login for the first time
const store = useStore()
const freshLogin = computed(() => store.state.freshLogin)
if (freshLogin.value) {
  const isSignupFilled = isComplete()
  if (isSignupFilled) {
    store.commit('CHANGE_FRESH_LOGIN', false)
  } else {
    store.dispatch('logoutUser')
  }
}

// Init api call
const homeData = computed(() => store.state.homeData)
store.dispatch('fetchHomeData')
</script>

<style lang="scss" scoped>
.home-wrapper {
  width: 100%;
}
.middle {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 40px;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0 40px;
  background-color: #fff;
}
.navs {
  width: 100%;
  margin-top: -40px;
  background-color: #000;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.down {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 40px;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 50px;
  background-color: #fff;
  // background: linear-gradient(to bottom, #fff 80px, #eee 120px);
}
.home-hr {
  width: 80%;
  height: 1px;
  background-color: rgba(#000, 0.15);
}
</style>
