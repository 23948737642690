<template>
  <div class="profile-wrapper">
    <router-link :to="{ name: 'ProfileEdit' }" class="profile">
      <div class="profile__link">
        <img :src="logo" v-show="flip" @load="flip = true" />
        <img src="@img/preload-logo.svg" v-if="!flip" />
      </div>
      <!-- <span class="profile__text">پروفایل</span> -->
    </router-link>
    <a class="money" @click="$store.dispatch('profile/handlePayModal', 'open')">
      <template v-if="wallet || wallet === 0">
        <span class="money__title">موجودی</span>
        <svg
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; height: 30px"
          viewBox="0 0 512 512"
          class="money__add"
        >
          <path
            d="M256 0c141.39,0 256,114.61 256,256 0,141.39 -114.61,256 -256,256 -141.39,0 -256,-114.61 -256,-256 0,-141.39 114.61,-256 256,-256zm-13.13 380.37l0 -111.24 -111.24 0c-7.22,0 -13.13,-5.91 -13.13,-13.13l0 0c0,-7.22 5.91,-13.13 13.13,-13.13l111.24 0 0 -111.24c0,-7.22 5.91,-13.13 13.13,-13.13l0 0c7.23,0 13.14,5.91 13.14,13.13l0 111.24 111.23 0c7.22,0 13.13,5.91 13.13,13.13l0 0c0,7.22 -5.91,13.13 -13.13,13.13l-111.23 0 0 111.24c0,7.22 -5.92,13.13 -13.14,13.13l0 0c-7.22,0 -13.13,-5.91 -13.13,-13.13zm13.13 -354.99c127.37,0 230.62,103.25 230.62,230.62 0,127.37 -103.25,230.62 -230.62,230.62 -127.37,0 -230.62,-103.25 -230.62,-230.62 0,-127.37 103.25,-230.62 230.62,-230.62z"
          />
        </svg>
        <p class="money__price">{{ wallet.toLocaleString() }}</p>
      </template>
      <div class="line" v-else />
    </a>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'HomeProfile',
  props: {
    logo: {
      type: String,
      default: null
    },
    wallet: {
      type: [String, Number]
    }
  },
  setup() {
    const flip = ref(false)
    return { flip }
  }
}
</script>

<style lang="scss" scoped>
.profile-wrapper {
  background-color: #fff;
  width: 92%;
  height: 120px;
  margin: 5px auto 0;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 18px 0;

  .money {
    margin: auto 0;
    border-right: 1px solid #e3e3e3;
    display: grid;
    height: 85px;
    grid-template-columns: auto 140px 26px auto;
    grid-template-rows: 30px 55px;
    align-items: center;
    width: 65%;
    cursor: pointer;
    .money__title {
      justify-self: center;
      align-self: flex-end;
      grid-column: 1/5;
      font-size: 14px;
      color: #a7a7a7;
      font-weight: bold;
    }
    .money__add {
      align-self: flex-start;
      justify-self: flex-end;
      fill: #aaa;
      grid-row: 2/3;
      grid-column: 3/4;
      width: 24px;
      height: 24px;
      margin-top: 2.5px;
    }
    .money__price {
      align-self: flex-start;
      grid-row: 2/3;
      justify-self: flex-end;
      grid-column: 2/3;
      color: #1a1a1a;
      height: 45px;
      font-size: 26px;
      font-weight: bold;
      margin: 4px 0 0;
      &::after {
        content: 'R';
        color: #a7a7a7;
        font-size: 14px;
        top: -8px;
        left: 3px;
        position: relative;
      }
    }
  }
  .profile {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile__link {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 60px;
      height: 60px;
      box-shadow: 0 0 2px 1px #f0f0f0;
      border-radius: 50%;
      overflow: hidden;
      svg {
        width: 60px;
        height: 60px;
      }
      img {
        // width: 60px;
        height: 60px;
        filter: saturate(0.85);
      }
    }
    .profile__text {
      margin-top: 5px;
      font-size: 14px;
      color: #aaa;
      font-weight: bold;
      margin-left: 4px;
    }
  }
}

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
.line {
  grid-row: 1/-1;
  grid-column: 1/-1;
  align-self: center;
  justify-self: center;
  width: 80%;
  height: 8px;
  border-radius: 4px;

  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
  animation: shine-lines $animation-duration infinite linear;
}
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}
</style>
