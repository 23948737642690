<template>
  <div class="cmp-items-wrapper">
    <section class="down-wrapper">
      <div class="vipon-logo">
        <img src="@img/vipon-white.svg" />
        <p>All rights reserved &copy; 2021</p>
      </div>
      <div class="social-btns">
        <a href="http://www.vipon.ir" target="_blanc" class="social-btn">
          <svg
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            class="social-btn__icon"
          >
            <path
              d="M328.719,366.51c-2.021-2.438-5.031-3.844-8.198-3.844H191.479c-3.167,0-6.177,1.406-8.198,3.844
				c-2.031,2.438-2.865,5.646-2.292,8.76C196.323,458.333,225.771,512,256,512s59.677-53.667,75.01-136.729
				C331.583,372.156,330.75,368.948,328.719,366.51z"
            />
            <path
              d="M499.833,178.083c-1.406-4.417-5.51-7.417-10.156-7.417H369.563c-3.01,0-5.885,1.271-7.906,3.51
				c-2.031,2.24-3.01,5.229-2.708,8.229c2.469,24.5,3.719,49.271,3.719,73.594s-1.25,49.094-3.719,73.594
				c-0.302,3,0.677,5.99,2.708,8.229c2.021,2.24,4.896,3.51,7.906,3.51h120.115c4.646,0,8.75-3,10.156-7.417
				C507.906,308.635,512,282.427,512,256S507.906,203.365,499.833,178.083z"
            />
            <path
              d="M353.292,140.438c0.865,5.135,5.313,8.896,10.521,8.896h107.667c3.76,0,7.25-1.99,9.177-5.219
				c1.917-3.24,1.99-7.25,0.188-10.552C448.823,74.885,393.813,30.188,329.896,10.938c-4.115-1.271-8.646,0.135-11.385,3.521
				c-2.719,3.396-3.115,8.094-1,11.885C333.052,54.198,345.427,93.646,353.292,140.438z"
            />
            <path
              d="M326.719,170.667H185.281c-5.448,0-10.021,4.104-10.604,9.521c-2.656,24.625-4.01,50.135-4.01,75.813
				s1.354,51.188,4.01,75.813c0.583,5.417,5.156,9.521,10.604,9.521h141.438c5.448,0,10.021-4.104,10.604-9.521
				c2.656-24.625,4.01-50.135,4.01-75.813s-1.354-51.188-4.01-75.813C336.74,174.771,332.167,170.667,326.719,170.667z"
            />
            <path
              d="M40.521,149.333h107.667c5.208,0,9.656-3.76,10.521-8.896c7.865-46.792,20.24-86.24,35.781-114.094
				c2.115-3.792,1.719-8.49-1-11.885c-2.729-3.375-7.24-4.802-11.385-3.521C118.188,30.188,63.177,74.885,31.156,133.563
				c-1.802,3.302-1.729,7.313,0.188,10.552C33.271,147.344,36.76,149.333,40.521,149.333z"
            />
            <path
              d="M150.344,337.823c2.031-2.24,3.01-5.229,2.708-8.229c-2.469-24.5-3.719-49.271-3.719-73.594s1.25-49.094,3.719-73.594
				c0.302-3-0.677-5.99-2.708-8.229c-2.021-2.24-4.896-3.51-7.906-3.51H22.323c-4.646,0-8.75,3-10.156,7.417
				C4.094,203.365,0,229.573,0,256s4.094,52.635,12.167,77.917c1.406,4.417,5.51,7.417,10.156,7.417h120.115
				C145.448,341.333,148.323,340.063,150.344,337.823z"
            />
            <path
              d="M158.708,371.563c-0.865-5.135-5.313-8.896-10.521-8.896H40.521c-3.76,0-7.25,1.99-9.177,5.219
				c-1.917,3.24-1.99,7.25-0.188,10.552c32.021,58.677,87.031,103.375,150.948,122.625c1.01,0.313,2.042,0.458,3.073,0.458
				c3.177,0,6.25-1.427,8.313-3.979c2.719-3.396,3.115-8.094,1-11.885C178.948,457.802,166.573,418.354,158.708,371.563z"
            />
            <path
              d="M471.479,362.667H363.813c-5.208,0-9.656,3.76-10.521,8.896c-7.865,46.792-20.24,86.24-35.781,114.094
				c-2.115,3.792-1.719,8.49,1,11.885c2.063,2.552,5.135,3.979,8.313,3.979c1.021,0,2.063-0.146,3.073-0.458
				c63.917-19.25,118.927-63.948,150.948-122.625c1.802-3.302,1.729-7.313-0.188-10.552
				C478.729,364.656,475.24,362.667,471.479,362.667z"
            />
            <path
              d="M183.281,145.49c2.021,2.438,5.031,3.844,8.198,3.844h129.042c3.167,0,6.177-1.406,8.198-3.844
				c2.031-2.438,2.865-5.646,2.292-8.76C315.677,53.667,286.229,0,256,0s-59.677,53.667-75.01,136.729
				C180.417,139.844,181.25,143.052,183.281,145.49z"
            />
          </svg>
          <span class="social-btn__text">وبسایت</span>
        </a>
        <a
          class="social-btn"
          href="https://www.instagram.com/vipon.ir/"
          target="_blanc"
        >
          <svg class="social-btn__icon" viewBox="0 0 24 24">
            <path
              d="m12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z"
            />
            <path
              d="m16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941-2.308 2.308-2.013 5.418-2.013 9.979 0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z"
            />
            <circle cx="18.406" cy="5.595" r="1.439" />
          </svg>
          <span class="social-btn__text">اینستاگرام</span>
        </a>
        <a
          href="https://www.youtube.com/channel/UCjoVPNDzsqm5otBxMM-Q8MA"
          class="social-btn"
          target="_blanc"
        >
          <svg viewBox="0 0 24 24" class="social-btn__icon">
            <path
              d="m23.469 5.929.03.196c-.29-1.029-1.073-1.823-2.068-2.112l-.021-.005c-1.871-.508-9.4-.508-9.4-.508s-7.51-.01-9.4.508c-1.014.294-1.798 1.088-2.083 2.096l-.005.021c-.699 3.651-.704 8.038.031 11.947l-.031-.198c.29 1.029 1.073 1.823 2.068 2.112l.021.005c1.869.509 9.4.509 9.4.509s7.509 0 9.4-.509c1.015-.294 1.799-1.088 2.084-2.096l.005-.021c.318-1.698.5-3.652.5-5.648 0-.073 0-.147-.001-.221.001-.068.001-.149.001-.23 0-1.997-.182-3.951-.531-5.846zm-13.861 9.722v-7.293l6.266 3.652z"
            />
          </svg>
          <span class="social-btn__text">یوتیوب</span>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomeDown'
}
</script>

<style scoped lang="scss">
.down-wrapper {
  // margin-top: -40px;
  width: 100%;
  padding: 25px 0 0;
  border-radius: 40px 40px 0 0;
  position: relative;
  background: #000;
  // height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .vipon-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    img {
      position: relative;
      right: 5px;
      width: 120px;
    }
    p {
      margin: 3px 0 5px;
      color: #333;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.social-btns {
  padding: 15px 10px 50px;
  max-width: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-btn {
  width: 70px;
  height: 70px;
  background-color: #111;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  position: relative;
}
.social-btn__icon {
  width: 32px;
  height: 32px;
  fill: #282828;
}
.social-btn__text {
  position: absolute;
  color: #252525;
  bottom: -25px;
  font-size: 14px;
}
</style>
