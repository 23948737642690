<template>
  <div class="links">
    <div class="links__dark">
      <router-link class="link" :to="{ name: 'BonusHome' }">
        <svg
          class="link__icon"
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; height: 41px;"
          viewBox="0 0 700 512"
        >
          <path
            d="M350 0c141.39,0 256,114.61 256,256 0,141.38 -114.61,256 -256,256 -141.39,0 -256,-114.62 -256,-256 0,-141.39 114.61,-256 256,-256zm54.28 167.78l0.63 -0.05c6.98,-0.65 12.33,-6.5 12.33,-13.51 0,-7.01 -5.35,-12.87 -12.33,-13.51l-0.6 -0.06 -40.75 0 0 -43.42 -0.05 -0.62c-0.65,-6.97 -6.5,-12.34 -13.51,-12.34 -7.02,0 -12.87,5.36 -13.51,12.34l-0.06 0.59 0 43.45 -8.89 0c-35.44,0 -64.45,29.01 -64.45,64.46 0,35.44 29.01,64.46 64.45,64.46l44.91 0c20.54,0 37.33,16.78 37.33,37.32 0,20.54 -16.79,37.32 -37.33,37.32l-76.75 0 -0.61 0.06c-6.98,0.64 -12.34,6.5 -12.34,13.51 0,7.01 5.36,12.88 12.34,13.51l0.6 0.06 40.74 0 0 43.45 0.06 0.59c0.64,6.98 6.5,12.33 13.51,12.33 7.01,0 12.86,-5.36 13.51,-12.33l0.05 -0.62 0 -43.42 8.89 0c35.45,0 64.46,-29.02 64.46,-64.46 0,-35.44 -29.01,-64.46 -64.46,-64.46l-44.9 0c-20.54,0 -37.32,-16.78 -37.32,-37.32 0,-20.54 16.78,-37.33 37.32,-37.33l76.73 0zm-54.28 -140.75c126.45,0 228.97,102.51 228.97,228.97 0,126.45 -102.52,228.96 -228.97,228.96 -126.45,0 -228.97,-102.51 -228.97,-228.96 0,-126.46 102.52,-228.97 228.97,-228.97z"
          />
        </svg>
        <span class="link__title" style="position: relative; top: 1px;"
          >بونوس</span
        >
      </router-link>
      <i class="link__hr"></i>
      <router-link class="link" :to="{ name: 'LotteryHome' }">
        <svg
          class="link__icon"
          width="750px"
          height="512px"
          viewBox="0 0 750 512"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path
              class="fil0"
              d="M679.26 322.18l0 -109.75c0,-52.39 -42.86,-95.25 -95.24,-95.25l-105.2 0 1.2 -0.74c3.78,-2.32 7.22,-4.99 10.36,-8.13 24.78,-24.77 24.77,-64.96 0,-89.73 -24.77,-24.77 -64.96,-24.77 -89.73,0 -10.16,10.16 -11.57,16.38 -17.21,28.84l-0.89 1.95 -7.55 27.39 -7.54 -27.36 -0.9 -1.98c-5.64,-12.45 -7.05,-18.68 -17.21,-28.84 -24.77,-24.77 -64.96,-24.77 -89.73,0 -24.77,24.77 -24.78,64.96 0,89.73 3.14,3.14 6.58,5.81 10.36,8.13l1.2 0.74 -105.2 0c-52.38,0 -95.24,42.86 -95.24,95.25l0 109.75 39.17 0 0 94.58c0,52.38 42.86,95.24 95.24,95.24l339.69 0c52.39,0 95.25,-42.86 95.25,-95.24l0 -94.58 39.17 0zm-39.17 -27.19l-27.19 0 0 0.06 -475.81 0 0 -0.06 -27.18 0 0 0.06 -11.99 0 0 -82.62c0,-37.44 30.63,-68.06 68.06,-68.06l418.03 0c37.44,0 68.06,30.62 68.06,68.06l0 82.62 -11.98 0 0 -0.06zm-27.19 27.19l0 94.58c0,37.43 -30.62,68.05 -68.06,68.05l-339.69 0c-37.43,0 -68.06,-30.62 -68.06,-68.05l0 -94.58 475.81 0zm-334.06 -284.38c14.16,-14.16 37.13,-14.16 51.29,0 7.22,7.22 7.71,12.1 11.69,20.9l16.02 58.11 -0.68 -0.19c-18.32,-5.04 -65.77,-14.98 -78.32,-27.53 -14.16,-14.16 -14.16,-37.13 0,-51.29zm192.32 0c14.16,14.16 14.16,37.13 0,51.29 -12.55,12.55 -60,22.49 -78.33,27.53l-0.67 0.19 16.04 -58.17c3.96,-8.74 4.45,-13.62 11.67,-20.84 14.16,-14.16 37.13,-14.16 51.29,0z"
            />
          </g>
        </svg>
        <span class="link__title" style="position: relative; top: 1px;"
          >قرعه‌کشی</span
        >
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.links {
  width: calc(94% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  // margin-bottom: 30px;
}
.links__dark {
  width: 100%;
  height: 110px;
  background-color: #000;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  width: calc(50% - 1px);
  position: relative;
  z-index: auto;
  border-radius: 15px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.link__icon {
  fill: #c69d4e;
  height: 40px;
  width: auto;
  margin-bottom: 10px;
}
.link__title {
  font-size: 13px;
  text-align: center;
  color: white;
}
.link__hr {
  height: 70%;
  width: 2px;
  background-color: rgba(#fff, 0.2);
}
</style>
