<template>
  <header>
    <navbar nav-type="home" />
    <img src="@img/customer-bg.jpg" />
    <h3>VIP SERVICES</h3>
    <h4>FOR VIP CUSTOMERS</h4>
  </header>
</template>

<script>
export default {
  name: 'HomeHeader'
}
</script>

<style scoped lang="scss">
header {
  overflow: hidden;
  position: relative;
  // background-image: url(~@img/home-bg.svg);
  // background-position: center;
  // background-size: cover;
  // background-repeat: repeat-x;
  height: 230px;
  background-color: #1f1f1f;
}
img {
  position: absolute;
  top: -5px;
  right: -250px;
  height: 120%;
}
h3 {
  position: absolute;
  color: #fff;
  transform: translateX(-100px);
  left: 20px;
  text-align: left;
  top: 90px;
  font-size: 22px;
  opacity: 0;
  animation: moveText1 5s infinite;
}
h4 {
  opacity: 0;
  position: absolute;
  color: #fff;
  transform: translateX(-100px);
  left: 20px;
  font-size: 13px;
  top: 120px;
  animation: moveText2 5s infinite;
}
@keyframes moveText1 {
  0% {
    transform: translateX(-20px);
  }
  5% {
    opacity: 0;
  }
  20% {
    transform: translateX(0);
  }
  30% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  81% {
    transform: translateX(0px);
  }
  94% {
    opacity: 0;
  }

  100% {
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes moveText2 {
  8% {
    transform: translateX(-10px);
  }
  12% {
    opacity: 0;
  }
  28% {
    transform: translateX(0);
  }
  40% {
    opacity: 0.7;
  }
  72% {
    transform: translateX(0);
    opacity: 0.7;
  }
  90% {
    opacity: 0;
  }
  95% {
    transform: translateX(-10px);
  }
}
</style>
