<template>
  <div class="nav-wrapper">
    <router-link class="nav" :to="{ name: 'SurveyHome' }">
      <svg
        class="nav__icon"
        width="880px"
        height="512px"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 880 512"
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            class="fil0"
            d="M514.16 91.98c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0zm0 27.2c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm-390.71 0c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm0 -27.2c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0z"
          />
          <path
            class="fil0"
            d="M546.99 223.95l88.25 88.24 88.24 -88.24 0.08 -0.09 5.15 -5.14 47.8 -47.8 7.84 -7.85 39.22 -39.21 30.93 -30.93c5.29,-5.29 13.93,-5.29 19.22,0l0 0c5.28,5.29 5.28,13.93 0,19.22l-30.93 30.92 -39.22 39.22 -7.84 7.85 -47.8 47.8 -39.22 39.22 -0.09 0.08 -59.26 59.27c-4.17,4.17 -8.27,5.62 -13.61,5.72 -3.11,0.06 -10.21,-1.3 -13.11,-4.2l-94.86 -94.86c-5.29,-5.29 -5.29,-13.94 0,-19.22l0 0c5.28,-5.29 13.93,-5.29 19.21,0z"
          />
        </g>
      </svg>

      <span class="nav__title">نظرسنجی</span>
    </router-link>
    <!-- <router-link class="nav" :to="{ name: 'OfferHome' }">
      <svg class="nav__icon" viewBox="0 0 700 512">
        <path
          d="M189.67 484.6l148.71 0c7.49,0 13.61,6.11 13.61,13.61 0,7.49 -6.13,13.59 -13.61,13.59l-178.23 0c-15.57,0 -15.51,-13.52 -15.51,-26.79l0 -166.94c0,-7.49 6.11,-13.62 13.61,-13.62 7.49,0 13.6,6.12 13.6,13.62l0 144.87 355.52 -457.66c4.62,-5.95 13.22,-7.04 19.18,-2.41 5.97,4.63 7.07,13.23 2.43,19.2l-359.31 462.53zm21.28 -226.84c-71.19,0 -128.87,-57.7 -128.87,-128.88 0,-71.16 57.7,-128.88 128.87,-128.88 71.18,0 128.9,57.69 128.9,128.88 0,71.21 -57.71,128.88 -128.9,128.88zm278.06 254.24c-71.17,0 -128.86,-57.71 -128.86,-128.88 0,-71.16 57.7,-128.88 128.86,-128.88 71.19,0 128.91,57.69 128.91,128.88 0,71.21 -57.71,128.88 -128.91,128.88zm-278.06 -484.64c-55.98,0 -101.51,45.56 -101.51,101.52 0,55.97 45.53,101.52 101.51,101.52 55.97,0 101.53,-45.54 101.53,-101.52 0,-55.98 -45.56,-101.52 -101.53,-101.52zm278.06 254.24c-55.97,0 -101.49,45.56 -101.49,101.52 0,55.97 45.52,101.52 101.49,101.52 55.98,0 101.54,-45.53 101.54,-101.52 0,-55.98 -45.56,-101.52 -101.54,-101.52z"
        />
      </svg>
      <span class="nav__title">آفر</span>
    </router-link> -->
    <!-- <router-link class="nav" :to="{ name: 'NoticeHome' }">
      <svg class="nav__icon" width="700px" height="512px" viewBox="0 0 700 512">
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            d="M235.15 148.21c-62.71,0 -113.55,50.84 -113.55,113.56 0,62.71 50.84,113.55 113.55,113.55 62.72,0 113.56,-50.84 113.56,-113.55 0,-62.72 -50.84,-113.56 -113.56,-113.56zm229.91 -141.31c-4.06,-3.66 -7.19,-6.9 -13.05,-6.9 -7.47,0 -13.53,6.06 -13.53,13.53 0,7.1 4.52,10.13 9.28,14.45 66.02,60.08 103.41,144.46 103.41,233.79 0,85.7 -34.4,166.98 -95.86,226.68 -2.7,2.63 -4.31,6.24 -4.31,10.02 0,7.47 6.06,13.53 13.53,13.53 3.23,0 6.33,-1.14 8.79,-3.24 1.06,-0.91 2.09,-1.88 3.08,-2.86 65.47,-64.69 102,-152.07 102,-244.13 0,-97.52 -41.01,-189.58 -113.34,-254.87zm-89.31 429.21c5.94,0 9.55,-3.89 13.6,-7.63 46.6,-43.12 72.91,-103.19 72.91,-166.71 0,-63.9 -26.62,-124.28 -73.69,-167.43 -3.88,-3.55 -6.32,-6.16 -11.93,-6.16 -7.48,0 -13.54,6.06 -13.54,13.54 0,2.71 0.8,5.33 2.32,7.58 1.77,2.6 5.07,4.98 7.39,7.18 40.01,37.92 62.5,90.14 62.5,145.29 0,56.46 -23.59,109.81 -65.26,147.85 -4.58,4.18 -7.83,6.15 -7.83,12.96 0,7.48 6.06,13.53 13.53,13.53zm-140.6 -260.6c47.64,0 86.26,38.62 86.26,86.26 0,47.64 -38.62,86.26 -86.26,86.26 -47.64,0 -86.26,-38.62 -86.26,-86.26 0,-47.64 38.62,-86.26 86.26,-86.26z"
          />
        </g>
      </svg>

      <span class="nav__title">اطلاع رسانی</span>
    </router-link> -->
    <!-- <router-link class="nav" :to="{ name: 'EventHome' }">
      <svg
        class="nav__icon"
        width="700px"
        height="512px"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 700 512"
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            class="fil0"
            d="M350 0c141.39,0 256,114.61 256,256 0,141.38 -114.61,256 -256,256 -141.39,0 -256,-114.62 -256,-256 0,-141.39 114.61,-256 256,-256zm-119.57 116.86l105.97 106.24 0 -102.92c0,-7.48 6.12,-13.6 13.6,-13.6l0 0c7.48,0 13.6,6.12 13.6,13.6l0 135.6c0,6.19 -4.2,11.46 -9.89,13.08l-0.01 0.01 -0.11 0.03c-4.6,1.31 -9.78,0.15 -13.38,-3.46l-9.35 -9.37 -0.09 -0.09 -129.05 -129.38c-5.28,-5.29 -5.27,-13.95 0.03,-19.23l0 0c5.3,-5.28 13.95,-5.27 19.23,0.02l9.42 9.44 0.03 0.03zm119.57 -89.83c126.45,0 228.97,102.51 228.97,228.97 0,126.45 -102.52,228.96 -228.97,228.96 -126.45,0 -228.97,-102.51 -228.97,-228.96 0,-126.46 102.52,-228.97 228.97,-228.97z"
          />
        </g>
      </svg>

      <span class="nav__title">مناسبت ها</span>
    </router-link> -->
    <router-link class="nav" :to="{ name: 'CustomerBirthday' }">
      <svg class="nav__icon" viewBox="0 0 512 512">
        <path
          d="m497 416.999h-10v-163c0-24.813-20.187-45-45-45h-139v-73c0-8.284-6.716-15-15-15h-17v-19.689c37.732-9.026 41.302-50.501 18.885-73.102l-23.157-23.693c-5.884-6.02-15.581-6.019-21.462.007l-22.537 23.09c-9.324 9.067-14.456 21.205-14.456 34.206 0 20.015 12.665 34.694 32.728 39.493v19.689h-17c-8.284 0-15 6.716-15 15v73h-139.001c-24.813 0-45 20.187-45 45v163h-10c-8.284 0-15 6.716-15 15v65c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-65c0-8.285-6.716-15.001-15-15.001zm-258.728-355.182c0-4.855 1.933-9.39 5.443-12.768.249-.239.137-.13 12.291-12.583l12.468 12.756c3.49 3.49 5.253 7.955 5.253 12.594 0 2.766 0 11.182-17.728 11.182s-17.727-8.415-17.727-11.181zm.728 89.182h34v58h-34zm-169 88h372c8.271 0 15 6.729 15 15v48.285c-3.435 1.756-6.556 2.715-9 2.715-7.261 0-17.575-7.846-21.394-11.606-2.087-2.087-21.017-20.394-42.606-20.394s-40.52 18.306-42.567 20.354c-3.857 3.8-14.172 11.646-21.433 11.646s-17.575-7.846-21.394-11.606c-2.087-2.087-21.017-20.394-42.606-20.394s-40.52 18.306-42.567 20.354c-3.857 3.8-14.172 11.646-21.433 11.646s-17.575-7.846-21.394-11.606c-2.087-2.087-21.017-20.394-42.606-20.394s-40.52 18.306-42.567 20.354c-3.857 3.8-14.172 11.646-21.433 11.646-2.444 0-5.565-.96-9-2.715v-48.285c0-8.271 6.729-15 15-15zm-15 95.004c3.041.655 6.044.996 9 .996 21.59 0 40.52-18.306 42.567-20.354 3.857-3.8 14.172-11.646 21.433-11.646s17.575 7.846 21.394 11.606c2.087 2.087 21.017 20.394 42.606 20.394s40.52-18.306 42.567-20.354c3.857-3.8 14.172-11.646 21.433-11.646s17.575 7.846 21.394 11.606c2.087 2.087 21.017 20.394 42.606 20.394s40.52-18.306 42.567-20.354c3.857-3.8 14.172-11.646 21.433-11.646s17.575 7.846 21.394 11.606c2.087 2.087 21.017 20.394 42.606 20.394 2.956 0 5.959-.341 9-.996v82.996h-402zm427 147.996h-452v-35h452z"
        />
      </svg>
      <span class="nav__title">تولد</span>
    </router-link>
    <router-link class="nav" :to="{ name: 'GiftHome' }">
      <svg
        class="nav__icon"
        viewBox="0 0 700 512"
        style="height: 39px;position:relative; top: 1px;"
      >
        <path
          d="M389.45 192.38l0 -0.03 0.28 -0.03c7.01,-0.63 12.4,-6.53 12.4,-13.58 0,-7.05 -5.39,-12.95 -12.4,-13.58l-0.28 -0.03 0 -0.03 -26.76 0 0 -28.74 -0.03 0 -0.02 -0.27c-0.64,-7.02 -6.54,-12.4 -13.59,-12.4 -7.04,0 -12.95,5.38 -13.58,12.4l-0.02 0.27 -0.03 0 0 28.74 -2.86 0c-28.06,0 -51.02,22.96 -51.02,51.02 0,28.06 22.96,51.03 51.02,51.03l32.99 0c13.06,0 23.74,10.68 23.74,23.74 0,13.06 -10.68,23.75 -23.74,23.75l-56.89 0 0 0.03 -0.28 0.02c-7.01,0.64 -12.39,6.54 -12.39,13.59 0,7.04 5.38,12.95 12.39,13.58l0.28 0.02 0 0.03 26.76 0 0 28.75 0.03 0 0.02 0.27c0.63,7.01 6.54,12.4 13.58,12.4 7.05,0 12.95,-5.38 13.59,-12.4l0.02 -0.27 0.03 0 0 -28.75 2.86 0c28.06,0 51.02,-22.96 51.02,-51.02 0,-28.06 -22.96,-51.02 -51.02,-51.02l-32.99 0c-13.06,0 -23.74,-10.69 -23.74,-23.75 0,-13.06 10.68,-23.74 23.74,-23.74l56.89 0zm-318.25 -165.1l-23.92 23.92 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 51.2 51.2 -51.2 51.2 23.92 23.92 519.72 0c37.25,0 67.72,-30.47 67.72,-67.72l0 -322.01c0,-37.24 -30.47,-67.71 -67.72,-67.71l-519.72 0zm-5.91 484.72l-51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 51.21 -51.2 -51.21 -51.2 49.64 -49.64 0 -1.56 1.57 0 33.19 0 492.44 0c52.25,0 95,42.75 95,94.99l0 322.02c0,52.24 -42.75,94.99 -95,94.99l-492.44 0 -33.19 0z"
        />
      </svg>
      <span class="nav__title" style="position: relative; bottom: 2px;"
        >بن خرید</span
      >
    </router-link>
    <!-- <router-link class="nav" :to="{ name: 'ReminderHome' }">
      <svg class="nav__icon" width="750px" height="512px" viewBox="0 0 750 512">
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            d="M375 0c178.96,0 324.03,94.38 324.03,210.8 0,70.62 -53.39,133.12 -135.33,171.37 -8.05,3.76 -12,6.91 -19.39,3.42 -9.62,-4.53 -9.7,-16.41 -1.02,-22.87 5.99,-4.46 14.88,-7.45 20.71,-10.43 65.82,-33.66 107.76,-84.54 107.76,-141.49 0,-55.57 -39.93,-105.37 -103.02,-139.02 13.24,16.3 20.66,34.53 20.66,53.78 0,69.3 -95.99,125.47 -214.4,125.47 -118.41,0 -214.4,-56.17 -214.4,-125.47 0,-19.25 7.42,-37.48 20.66,-53.78 -63.09,33.65 -103.02,83.45 -103.02,139.02 0,101.36 132.86,183.54 296.76,183.54 13.28,0 26.36,-0.55 39.18,-1.59l-65.22 -65.23c-5.29,-5.29 -5.3,-13.96 0,-19.26l0 0c5.3,-5.3 13.97,-5.3 19.27,0l87.24 87.25c2.76,2.75 4.13,9.3 4.21,12.62 -0.08,3.33 -1.45,9.87 -4.21,12.63l-87.26 87.27c-5.3,5.29 -13.97,5.29 -19.27,0l0 0c-5.3,-5.31 -5.29,-13.97 0,-19.27l69 -69c-14.05,1.21 -28.38,1.84 -42.94,1.84 -178.96,0 -324.03,-94.38 -324.03,-210.8 0,-116.42 145.07,-210.8 324.03,-210.8zm0 27.36c-44.03,0 -92.2,8.57 -131.38,29.42 -22.96,12.21 -50.32,33.27 -55.06,60.65 -0.46,2.69 -0.7,5.41 -0.7,8.13 0,2.73 0.24,5.45 0.7,8.14 4.74,27.37 32.1,48.43 55.06,60.65 39.18,20.84 87.35,29.41 131.38,29.41 44.03,0 92.2,-8.57 131.38,-29.41 22.95,-12.22 50.32,-33.28 55.05,-60.65 0.47,-2.69 0.71,-5.41 0.71,-8.14 0,-2.72 -0.24,-5.44 -0.71,-8.13 -4.73,-27.38 -32.1,-48.44 -55.05,-60.65 -39.18,-20.85 -87.35,-29.42 -131.38,-29.42z"
          />
        </g>
      </svg>

      <span class="nav__title">یادآوری</span>
    </router-link> -->
  </div>
</template>

<script>
export default {
  name: 'HomeNav'
}
</script>

<style lang="scss" scoped>
.nav-wrapper {
  width: 94%;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 5px;
  padding: 0 10px;
}
.nav {
  position: relative;
  z-index: auto;
  border-radius: 15px;
  background-color: #111;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav--gift {
  // grid-column: 1/-1;
}
.nav__icon {
  fill: #c69d4e;
  height: 40px;
  width: auto;
  margin-bottom: 10px;
}
.nav__title {
  font-size: 13px;
  text-align: center;
  color: white;
}
.credit-buttons {
  margin: 10px 0 0;
  width: 100%;
  grid-column: 1/-1;
}
.button--nav {
  width: 100%;
  background-color: #111;
  .button__text {
    color: #fff;
  }
}
</style>
