<script setup>
import { computed, reactive, ref, watchEffect, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StarRating from 'vue-star-rating'
import dateConvertor from '@composable/dateConvertor'
import { getSatsInfo } from '@service/ProfileService'
import { getCustomer } from '@service/CustomerService'
import { createTicket } from '@service/TicketService'
import SatisfactionSingle from './subComponents/SatisfactionSingle'

const stars = reactive({})
const count = ref(0)
const bars = reactive([])
const infoStatus = ref('')
const store = useStore()
const homeData = computed(() => store.state.homeData)
if (!homeData.value) store.dispatch('fetchHomeData')

const getInfo = async () => {
  infoStatus.value = 'loading'
  try {
    const { data } = await getSatsInfo({ star: 0 })
    count.value = data['count']
    data['stars'].forEach((item) => {
      stars[item.star] = item
      stars[item.star]['percent'] = ((item.count / count.value) * 100).toFixed(
        1
      )
    })
    infoStatus.value = 'resolved'
  } catch (ex) {
    infoStatus.value = 'failed'
  }
}

const comments = computed(() => store.state.comment.items)
const commentStatus = computed(() => store.state.comment.pageStatus)
const url = '/store/transaction/'
const query = { star: 0 }
const getComments = async () => {
  if (commentStatus.value == 'loading') return
  store.dispatch('comment/fetchRecords', { url, query })
}

// Initial api call
getInfo()
getComments()

// Create diagram
watchEffect(() => {
  if (infoStatus.value == 'resolved') {
    bars[0] = {
      id: 5,
      count: stars['5'] ? stars['5']['count'] : 0,
      color: '#00c209',
      length: stars['5'] ? stars['5']['percent'] + '%' : '3px',
      percent: stars['5'] ? stars['5']['percent'] + '%' : '0%'
    }
    bars[1] = {
      id: 4,
      count: stars['4'] ? stars['4']['count'] : 0,
      color: '#00be72',
      length: stars['4'] ? stars['4']['percent'] + '%' : '3px',
      percent: stars['4'] ? stars['4']['percent'] + '%' : '0%'
    }
    bars[2] = {
      id: 3,
      count: stars['3'] ? stars['3']['count'] : 0,
      color: '#f1f400',
      length: stars['3'] ? stars['3']['percent'] + '%' : '3px',
      percent: stars['3'] ? stars['3']['percent'] + '%' : '0%'
    }
    bars[3] = {
      id: 2,
      count: stars['2'] ? stars['2']['count'] : 0,
      color: '#ed7500',
      length: stars['2'] ? stars['2']['percent'] + '%' : '3px',
      percent: stars['2'] ? stars['2']['percent'] + '%' : '0%'
    }
    bars[4] = {
      id: 1,
      count: stars['1'] ? stars['1']['count'] : 0,
      color: '#d40000',
      length: stars['1'] ? stars['1']['percent'] + '%' : '5px',
      percent: stars['1'] ? stars['1']['percent'] + '%' : '0%'
    }
  }
})

// Reply customer
const router = useRouter()
const replyCustomer = async (id) => {
  let ticketId
  try {
    const { data: customer } = await getCustomer(id)
    if (customer['ticket_id'] > 0) ticketId = customer['ticket_id']
    else {
      try {
        const { data } = await createTicket(id)
        ticketId = data['ticket_id']
      } catch (ex) {
        console.log(ex)
      }
    }

    router.push({ name: 'TicketShow', params: { id: ticketId } })
  } catch (ex) {
    console.log(ex)
  }
}
// Get necessary items for date
const { localizeDate, getHour } = dateConvertor()

onBeforeUnmount(() => store.dispatch('comment/clearItems'))
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="نظر مشتریان" />
    <section
      class="load-pending load-pending--show"
      v-if="infoStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode v-else-if="infoStatus == 'failed'" @refresh="getPageData" />
    <section class="load-resolved" v-else>
      <div class="hist-body">
        <div class="hist-top"></div>
        <div class="sat-title">
          <!-- <svg
            class="sat-logo"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 800 512"
          >
            <path
              d="M581.02 74.98c48.35,48.35 74.98,112.64 74.98,181.02 0,68.38 -26.63,132.67 -74.98,181.02 -48.35,48.35 -112.64,74.98 -181.02,74.98 -68.38,0 -132.67,-26.63 -181.02,-74.98 -48.35,-48.35 -74.98,-112.64 -74.98,-181.02 0,-68.38 26.63,-132.67 74.98,-181.02 48.35,-48.35 112.64,-74.98 181.02,-74.98 68.38,0 132.67,26.63 181.02,74.98zm-181.02 408.6c125.49,0 227.58,-102.09 227.58,-227.58 0,-125.49 -102.09,-227.58 -227.58,-227.58 -125.49,0 -227.58,102.09 -227.58,227.58 0,125.49 102.09,227.58 227.58,227.58zm119.52 -189.25l27.12 8.83 -0.4 0.99c-6.54,16.22 -17.43,33.65 -28.63,47.03 -29.49,35.26 -69.09,54.27 -115.15,54.27 -46.46,0 -86.67,-19.36 -117.35,-53.96 -11.81,-13.33 -23.5,-30.58 -30.85,-46.85l-0.44 -0.97 26.71 -9.97 0.39 0.83c6.42,13.62 16.32,27.98 26.38,39.13 25.3,28.04 57.19,43.36 95.16,43.36 37.19,0 68.34,-14.76 92.44,-43 9.49,-11.13 18.54,-25.36 24.26,-38.85l0.36 -0.84zm-183.17 -116.07c0,14.64 -11.87,26.51 -26.51,26.51 -14.64,0 -26.5,-11.87 -26.5,-26.51 0,-14.64 11.86,-26.51 26.5,-26.51 14.64,0 26.51,11.87 26.51,26.51zm180.31 0c0,14.64 -11.86,26.51 -26.5,26.51 -14.64,0 -26.51,-11.87 -26.51,-26.51 0,-14.64 11.87,-26.51 26.51,-26.51 14.64,0 26.5,11.87 26.5,26.51z"
            />
          </svg> -->
          <div class="sat-percent">
            <h3>{{ count }}<span>نظر</span></h3>
            <h4>
              {{ homeData ? Math.ceil(homeData['rating'] * 20) : 0 }}%
              <span>رضایت</span>
            </h4>
          </div>
          <div class="sat-stars">
            <star-rating
              :rating="homeData ? homeData.rating : 0"
              :increment="0.1"
              read-only
              active-color="#c69d4e"
              border-color="#000"
              inactive-color="#333"
              :border-width="2"
              :show-rating="false"
              :star-size="35"
              direction="rtl"
            />
          </div>
        </div>
        <div class="hist-wrapper">
          <div class="chart-row" v-for="bar in bars" :key="bar.id">
            <div class="chart-row-stat">
              <span>{{ bar['id'] }}</span>
              <svg viewBox="0 0 512 512">
                <g>
                  <polygon
                    points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431
              255.898,401.21 416.035,502.431 369.263,318.842 		"
                  />
                </g>
              </svg>
              <h5>
                {{ bar['percent'] }}
              </h5>
              <h6>{{ bar['count'] }}</h6>
            </div>
            <div class="chart-bar-row">
              <div class="chart-bar">
                <i
                  :style="{
                    backgroundColor: bar['color'],
                    width: bar['length']
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="star-comments">
        <!-- <h5 class="star__comment">{{ count }}</h5> -->
        <div
          class="sats-empty"
          v-if="commentStatus == 'resolved' && comments.length === 0"
        >
          هیچ نظری برای شما ثبت نشده است.
        </div>
        <div
          :class="[
            'cmp-items-wrapper',
            comments.length === 1 && 'cmp-items--plain'
          ]"
          v-else
        >
          <SatisfactionSingle
            v-for="comment in comments"
            :key="comment['id']"
            :rating="comment['star']"
            :date="localizeDate(comment['created_at'])"
            :hour="getHour(comment['created_at'])"
            :desc="comment['desc']"
            :price="comment['price']"
            :name="comment['name']"
            :img="comment['image']"
            :group="comment['group']"
            :mobile="comment['mobile']"
            :customer-id="comment['wallet_id']"
            @reply="replyCustomer(comment['wallet_id'])"
          />
          <FailedMode @refresh="getComments" v-if="commentStatus == 'failed'" />
          <Loader v-if="commentStatus == 'loading'" />
          <Observer @intersect="getComments" />
        </div>
      </div>
    </section>
    <FixedFooter />
  </div>
</template>

<style scoped lang="scss">
.sat-title {
  background-color: #000;
  position: absolute;
  width: 96%;
  border-radius: 15px;
  padding: 20px 0 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}
.sat-percent {
  z-index: 2;
  color: #fff;
  text-align: center;
  font-size: 20px;
  animation: blinking 1.3s infinite;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  h3 {
    margin-left: 20px;
    span {
      margin-right: 5px;
      font-size: 16px;
    }
  }
  h4 {
    span {
      font-size: 16px;
      margin-right: -2px;
    }
  }
}
.sat-stars {
  direction: ltr;
  overflow: hidden;
}
.sat-logo {
  fill: #c69d4e;
  width: 52px;
  height: 52px;
  position: relative;
  bottom: 4px;
}
.hist-wrapper {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  direction: ltr;
  border-radius: 0 0 30px 30px;
  padding: 15px 5px 15px;
  .chart-row {
    position: relative;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 5px;
    margin: 4px 0;
    border-radius: 10px;
    // background-color: rgba(255, 255, 255, 0.03);

    .filter-link {
      position: absolute;
      height: 85%;
      left: -10px;
      top: 16px;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: auto 0;
      border-radius: 10px;
      width: 30px;
      svg {
        fill: #555;
        width: 20px;
        height: 20px;
        margin: 0 auto;
      }
    }
    .chart-bar-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;

      .chart-bar {
        background-color: rgba(#fff, 0.05);
        position: relative;
        border-radius: 2px;
        height: 7px;
        width: 100%;
        i {
          position: absolute;
          height: 100%;
          left: 0;
          border-radius: 2px;
          z-index: 1;
          width: 100px;
        }
      }
    }
    .chart-row-stat {
      direction: ltr;
      width: 100%;
      display: grid;
      grid-template-columns: 10px 30px auto 45px 40px;
      span {
        grid-column: 1/2;
        font-size: 14px;
        color: #fff;
        // padding-top: 2px;
      }
      svg {
        grid-column: 2/3;
        position: relative;
        bottom: 2px;
        fill: #555;
        width: 15px;
        height: 15px;
        margin-left: 3px;
      }
      h6 {
        direction: rtl;
        grid-row: 1/2;
        grid-column: 4/5;
        text-align: right;
        font-size: 14px;
        color: #555;
        margin: 0;
        margin-right: 6px;
        &::after {
          content: 'نظر';
          color: #555;
          font-size: 12px;
          margin-right: 6px;
        }
      }
      h5 {
        grid-row: 1/2;
        grid-column: 5/6;
        text-align: right;
        color: #fff;
        font-size: 14px;
        margin: 0;
        width: 35px;
      }
    }
  }
}
.star-comments {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 555px);
}
.star__comment {
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: #eee;
  text-align: center;
  &::after {
    content: 'نظر';
    font-size: 12px;
    margin-right: 5px;
    font-weight: normal;
  }
}
.sats-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 100%;
  background: #111;
  color: #eee;
}
.hist-body {
  padding: 0 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(to bottom, #080808 150px, #111 220px);
}
.hist-top {
  width: 100%;
  height: 180px;
  border-radius: 0 0 40px 40px;
}

// change in common css
.cmp-wrapper {
  padding-top: 0;
  background: #111;
}
.cmp-items-wrapper {
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
.cmp-items--plain {
  border-radius: 0;
}
.load-pending--show {
  height: calc(100vh - 50px);
}
</style>
