<script setup>
import { computed, reactive, watchEffect, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const props = defineProps({
  info: Object
})
const bars = reactive([])
const resolved = computed(() => bars.length > 0)

const heightSetter = (n, sum) => {
  const ans = 100 * (n / (sum !== 0 ? sum : 0.001)) + '%'
  return ans
}
const percentSetter = (n, sum) => {
  const ans = Math.round(100 * (n / (sum !== 0 ? sum : 0.001))) + '%'
  return ans
}

watchEffect(() => {
  if (props.info.ages) {
    const { ages: info } = props.info
    // calculate sum
    let sum = 0
    for (let key of Object.keys(info)) {
      sum += info[key]
    }

    bars[0] = {
      id: 1,
      label: '<20',
      ageName: 'کمتر از 20 سال',
      ageFrom: 0,
      ageTo: 20,
      count: info['age20'],
      height: heightSetter(info['age20'], sum),
      percent: percentSetter(info['age20'], sum)
    }
    bars[1] = {
      id: 2,
      label: '20-25',
      ageName: 'بین 20 تا 25 سال',
      ageFrom: 20,
      ageTo: 25,
      count: info['age25'],
      height: heightSetter(info['age25'], sum),
      percent: percentSetter(info['age25'], sum)
    }
    bars[2] = {
      id: 3,
      label: '25-30',
      ageName: 'بین 25 تا 30 سال',
      ageFrom: 25,
      ageTo: 30,
      count: info['age30'],
      height: heightSetter(info['age30'], sum),
      percent: percentSetter(info['age30'], sum)
    }
    bars[3] = {
      id: 4,
      label: '30-35',
      ageName: 'بین 30 تا 35 سال',
      ageFrom: 30,
      ageTo: 35,
      count: info['age35'],
      height: heightSetter(info['age35'], sum),
      percent: percentSetter(info['age35'], sum)
    }
    bars[4] = {
      id: 5,
      label: '35-40',
      ageName: 'بین 35 تا 40 سال',
      ageFrom: 35,
      ageTo: 40,
      count: info['age40'],
      height: heightSetter(info['age40'], sum),
      percent: percentSetter(info['age40'], sum)
    }
    bars[5] = {
      id: 6,
      label: '40-45',
      ageName: 'بین 40 تا 45 سال',
      ageFrom: 40,
      ageTo: 45,
      count: info['age45'],
      height: heightSetter(info['age45'], sum),
      percent: percentSetter(info['age45'], sum)
    }
    bars[6] = {
      id: 7,
      label: '45<',
      ageName: 'بیشتر از 45 سال',
      ageFrom: 45,
      ageTo: 100,
      count: info['age50'],
      height: heightSetter(info['age50'], sum),
      percent: percentSetter(info['age50'], sum)
    }
  }
})

// enter customers
const store = useStore()
const router = useRouter()
const enterCustomers = (age) => {
  if (age['count'] === 0)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'در این بازه سنی مشتری وجود ندارد.'
    })

  router.push({
    name: 'Customer',
    query: {
      age_from: age['ageFrom'],
      age_to: age['ageTo'],
      type: 3,
      ageName: age['ageName']
    }
  })
}

const enterUncompleted = () => {
  if (props.info.uncompleted === 0)
    return store.dispatch('addToast', {
      type: 'error',
      message: 'مشتری تکمیل نشده وجود ندارد.'
    })
  router.push({ name: 'Customer', query: { user_completed: 0 } })
}
</script>

<template>
  <div class="home-chart-wrapper">
    <loader v-if="!resolved" />
    <section class="load-resolved" v-else>
      <div class="hist-wrapper">
        <div class="hist-chart">
          <div class="chart-col" v-for="bar in bars" :key="bar['id']">
            <div class="bar-holder">
              <div class="chart-bar">
                <i
                  :style="{
                    backgroundColor: '#c69d4e',
                    height: bar['height'] == '0%' ? '2px' : bar['height']
                  }"
                />
              </div>
              <span
                class="count"
                :style="{ bottom: `calc(${bar['height']} + 1px)` }"
              >
                {{ bar['count'].toLocaleString() }}
              </span>
            </div>
            <div class="bottom-panel">
              <div class="label">{{ bar['label'] }}</div>
              <div class="percent">{{ bar['percent'] }}</div>
              <a
                @click="enterCustomers(bar)"
                :to="{ name: 'Customer', query: { age: bar['label'] } }"
                class="filter-link"
              >
                <svg viewBox="0 0 512 512">
                  <path
                    d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
                  />
                </svg>
              </a>
              <span class="right-border" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.hist-wrapper {
  width: 96%;
  background-color: #000;
  border-radius: 30px;
  padding: 15px 2% 50px;
  .hist-chart {
    margin-top: 50px;
    direction: ltr;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 295px;
    width: 100%;
    .chart-col {
      position: relative;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        .bottom-panel {
          border-radius: 15px 0 0 15px;
        }
      }
      &:last-child {
        .bottom-panel {
          border-radius: 0 15px 15px 0;
          .right-border {
            display: none;
          }
        }
      }
      .bar-holder {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .count {
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          color: #eee;
          font-size: 14px;
        }
        .chart-bar {
          position: relative;
          border-radius: 2px;
          height: 290px;
          width: 7px;
          background-color: rgba(#fff, 0.04);
          i {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            border-radius: 2px;
            z-index: 1;
          }
        }
      }
      .bottom-panel {
        width: 100%;
        background-color: #0b0b0b;
        padding: 10px 10px 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        .right-border {
          position: absolute;
          width: 1px;
          right: 0;
          height: 60px;
          background-color: #181818;
          margin: auto 0;
          top: 0;
          bottom: 0;
        }

        .label {
          font-size: 10px;
          color: #c69d4e;
        }
        .percent {
          font-size: 13px;
          color: #777;
        }
        .filter-link {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 20px;
          svg {
            fill: #555;
            width: 20px;
            height: 20px;
            margin: 0 auto;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
</style>
